.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/*
  Handle anything other than smartphones
*/
@media (min-width: 400px) and (max-width: 700px) {
  
    .App {
      position: relative;
      width: 50%;
      left: 25%;
    }
  
}

@media (min-width: 700px) {
  
    .App {
      position: relative;
      width: 30%;
      left: 35%;
    }
  
}

.App-shrek-image {
  width: 100%;
  height: 100%;
}

.App-image-container {
  width: 80%;
  height: 80%;
}

.App-counter-container {
  width: 100%;
  height: 20%;
}
